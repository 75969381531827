import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import Image from 'gatsby-image'

import * as S from './hero.styles'

interface HeroProps {}

const Hero: React.FC<HeroProps> = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "offerHero.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <S.Hero>
      <Image fluid={data.file.childImageSharp.fluid} />
    </S.Hero>
  )
}

export default Hero
