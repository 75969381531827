import styled, { css } from 'styled-components'

import ArrowComponent from 'src/assets/images/vectors/arrow.svg'

export const Btn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 5rem;
  border: 1px solid ${({ theme }) => theme.lightgray};
  border-top: 0;
  transition: all 0.5s ease;

  path {
    transition: all 0.5s ease;
  }

  ${({ theme }) => theme.mq.tablet} {
    width: 5rem;
    height: auto;
    border: 1px solid ${({ theme }) => theme.lightgray};
    border-left: 0;
  }

  :hover {
    background: ${({ theme }) => theme.red};

    path {
      fill: white;
    }
  }
`

export const Car = styled.article`
  max-width: 50rem;
  margin: 0 auto 4rem;
  transition: all 0.5s ease;
  cursor: pointer;

  :last-child {
    margin-bottom: 0;
  }

  ${({ theme }) => theme.mq.tablet} {
    display: flex;
    max-width: initial;
  }

  :hover {
    box-shadow: 0px 0px 20px -10px rgba(0, 0, 0, 0.75);
  }
`

export const Arrow = styled(ArrowComponent)`
  width: 3.2rem;
  transform: rotate(-90deg);
`

export const Cars = styled.section`
  max-width: 108rem;
  margin: 7rem auto 0;
  padding: 0 3.7rem;
  color: ${({ theme }) => theme.darkgray};
`

export const ImageContainer = styled.div`
  position: relative;

  ${({ theme }) => theme.mq.tablet} {
    flex-basis: 40%;

    div {
      height: 100%;
      object-fit: cover;
    }
  }
`
export const Details = styled.div`
  border: 1px solid ${({ theme }) => theme.lightgray};

  ${({ theme }) => theme.mq.tablet} {
    flex-basis: 60%;
  }
`

export const Description = styled.div`
  padding: 2.1rem 1.3rem;
`

export const Header = styled.h2`
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  font-size: ${({ theme }) => theme.font.size.headers.m};

  ${({ theme }) => theme.mq.tablet} {
    font-size: ${({ theme }) => theme.font.size.headers.l};
  }

  a {
    color: black;
    text-decoration: none;
  }
`

export const ShortDescription = styled.h3`
  margin: 2rem 0 1.2rem;
`

export const Type = styled.p``

export const CarDetails = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${({ theme }) => theme.mq.tablet} {
    height: 6.5rem;
  }
`

export const Box = styled.p`
  flex-basis: 50%;
  padding: 1.4rem 0;
  text-align: center;
  border: 1px solid ${({ theme }) => theme.lightgray};
  border-left: 0;

  :nth-child(2) {
    border-right: 0;
  }

  :nth-child(3) {
    border-top: 0;
  }

  :last-child {
    border-top: 0;
    border-right: 0;
  }

  ${({ theme }) => theme.mq.tablet} {
    display: flex;
    flex-basis: 25%;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0;

    :nth-child(2) {
      border-right: 1px solid ${({ theme }) => theme.lightgray};
    }

    :nth-child(3) {
      border-top: 1px solid ${({ theme }) => theme.lightgray};
    }

    :last-child {
      border-top: 1px solid ${({ theme }) => theme.lightgray};
    }
  }
`

export const Price = styled.h4`
  display: block;
  padding: 1.4rem 0;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-size: ${({ theme }) => theme.font.size.headers.l};
  text-align: center;

  ${({ theme }) => theme.mq.tablet} {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 6.5rem;
    padding: 0;
  }
`

export const Mask = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
`

const SoldReserved = css`
  position: absolute;
  top: 0;
  left: -3.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.7rem;
  height: 100%;
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  text-transform: uppercase;

  span {
    display: block;
    transform: rotate(-90deg);
  }
`

export const Sold = styled.div`
  color: ${({ theme }) => theme.lightgray};
  background: ${({ theme }) => theme.darkgray};

  ${SoldReserved};
`
export const Reserved = styled.div`
  color: ${({ theme }) => theme.darkgray};
  background: ${({ theme }) => theme.lightgray};

  ${SoldReserved};
`
