import React from 'react'
import { navigate, Link } from 'gatsby'
import Image from 'gatsby-image'

import * as S from './cars.styles'
import { FluidObject } from 'gatsby-image'

export interface Car {
  id: string
  carName: string
  carShortDescription: string
  carType: string
  carYear: string
  carMileage: string
  carEngineCapacity: string
  carEngineType: string
  carPrice: string
  slug: string
  sold: boolean
  reserved: boolean
  carSmallImage: {
    fluid: FluidObject
  }
}

interface CarsProps {
  cars: Car[]
}

const Cars: React.FC<CarsProps> = ({ cars }) => {
  const handleClick = async (slug: string) => {
    await navigate(slug)
  }

  return (
    <S.Cars>
      {cars.map(
        ({
          id,
          carName,
          carSmallImage,
          carShortDescription,
          carType,
          carYear,
          carMileage,
          carEngineCapacity,
          carEngineType,
          carPrice,
          slug,
          sold,
          reserved,
        }) => (
          <S.Car key={id} onClick={() => handleClick(`/oferta/${slug}`)}>
            <S.ImageContainer>
              <Image fluid={carSmallImage.fluid} />
              {reserved && (
                <S.Reserved>
                  <span>Rezerwacja</span>
                </S.Reserved>
              )}
              {sold && <S.Mask />}
              {sold && (
                <S.Sold>
                  <span>Sprzedany</span>
                </S.Sold>
              )}
            </S.ImageContainer>
            <S.Details>
              <S.Description>
                <S.Header>
                  <Link to={`/oferta/${slug}`}>{carName}</Link>
                </S.Header>
                <S.ShortDescription>{carShortDescription}</S.ShortDescription>
                <S.Type>{carType}</S.Type>
              </S.Description>
              <S.CarDetails>
                <S.Box>{carYear}</S.Box>
                <S.Box>{carMileage}</S.Box>
                <S.Box>{carEngineCapacity}</S.Box>
                <S.Box>{carEngineType}</S.Box>
              </S.CarDetails>
              <S.Price>{carPrice}</S.Price>
            </S.Details>
            <S.Btn to={`/oferta/${slug}`}>
              <S.Arrow />
            </S.Btn>
          </S.Car>
        )
      )}
    </S.Cars>
  )
}

export default Cars
