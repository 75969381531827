import styled from 'styled-components'

export const Hero = styled.section`
  width: 100%;
  height: 56rem;
  padding-top: 5rem;

  div {
    height: 100%;
    object-fit: cover;
  }

  ${({ theme }) => theme.mq.tablet} {
    height: 80vh;
    padding-top: 8rem;
  }
`
